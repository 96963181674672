import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Hr, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Grand Horizon Retreat
			</title>
			<meta name={"description"} content={"Вирушайте у виняткову подорож, де розкіш зустрічається з комфортом у серці вічної елегантності. У Grand Horizon Retreat ми пишаємося тим, що пропонуємо справді незабутні враження, адаптовані до бажань кожного гостя."} />
			<meta property={"og:title"} content={"Grand Horizon Retreat"} />
			<meta property={"og:description"} content={"Вирушайте у виняткову подорож, де розкіш зустрічається з комфортом у серці вічної елегантності. У Grand Horizon Retreat ми пишаємося тим, що пропонуємо справді незабутні враження, адаптовані до бажань кожного гостя."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-3.jpg?v=2024-05-24T13:59:02.936Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-3.jpg?v=2024-05-24T13:59:02.936Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-3.jpg?v=2024-05-24T13:59:02.936Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-3.jpg?v=2024-05-24T13:59:02.936Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-3.jpg?v=2024-05-24T13:59:02.936Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-3.jpg?v=2024-05-24T13:59:02.936Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-3.jpg?v=2024-05-24T13:59:02.936Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link3" />
		</Components.Header>
		<Section padding="100px 0 100px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-2.jpg?v=2024-05-24T13:59:02.938Z"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 5px 0px 0px"
						md-height="500px"
						srcSet="https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/3-2.jpg?v=2024-05-24T13%3A59%3A02.938Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/3-2.jpg?v=2024-05-24T13%3A59%3A02.938Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/3-2.jpg?v=2024-05-24T13%3A59%3A02.938Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/3-2.jpg?v=2024-05-24T13%3A59%3A02.938Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/3-2.jpg?v=2024-05-24T13%3A59%3A02.938Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/3-2.jpg?v=2024-05-24T13%3A59%3A02.938Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/3-2.jpg?v=2024-05-24T13%3A59%3A02.938Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/2-3.jpg?v=2024-05-24T13:59:02.937Z"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 0px 0px 5px"
						md-height="500px"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-3.jpg?v=2024-05-24T13%3A59%3A02.937Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-3.jpg?v=2024-05-24T13%3A59%3A02.937Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-3.jpg?v=2024-05-24T13%3A59%3A02.937Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-3.jpg?v=2024-05-24T13%3A59%3A02.937Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-3.jpg?v=2024-05-24T13%3A59%3A02.937Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-3.jpg?v=2024-05-24T13%3A59%3A02.937Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-3.jpg?v=2024-05-24T13%3A59%3A02.937Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					width="50%"
					padding="0px 0px 0px 80px"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="--headline2"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						Grand Horizon Retreat
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 20px 0px"
						width="50px"
						display="flex"
						justify-content="center"
						border-width="2px 0 0 0"
						border-color="--color-darkL1"
						border-style="dashed"
					/>
					<Text margin="0px 0px 25px 0px" font="--base" color="--darkL1" text-align="center">
						Вирушайте у виняткову подорож, де розкіш зустрічається з комфортом у серці вічної елегантності. У Grand Horizon Retreat ми пишаємося тим, що пропонуємо справді незабутні враження, адаптовані до бажань кожного гостя.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" background="--color-darkL2" quarkly-title="Description-11">
			<Override
				slot="SectionContent"
				flex-wrap="wrap"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
			/>
			<Box border-color="--color-light" width="100%" border-width="0px 0px 1px 0px" border-style="solid">
				<Text margin="0px 0px 2vh 0px" color="--light" font="--lead">
					Відкрийте наш світ
				</Text>
			</Box>
			<Box width="100%" margin="3vw 0px 0px 0px">
				<Text
					margin="0px 0px 0px 0px"
					font="--headline3"
					color="--light"
					letter-spacing="1.3px"
					lg-font="normal 400 70px/1.1em &quot;Inter&quot;, sans-serif"
					sm-font="normal 400 40px/1.1em &quot;Inter&quot;, sans-serif"
				>
					Grand Horizon Retreat — це більше, ніж просто місце призначення, це рай спокою та насолоди. Від наших ретельно обладнаних номерів до зручностей світового класу, кожен аспект вашого перебування створений для того, щоб побалувати та омолодити. Незалежно від того, чи шукаєте ви спокійний відпочинок від суєти повсякденного життя чи розкішну базу для своїх пригод, наш готель створений, щоб запропонувати неперевершену вишуканість і релаксацію.
				</Text>
			</Box>
			<Box
				margin="156px 0px 100px 0px"
				lg-margin="56px 0px 30px 0px"
				md-display="none"
				width="33.333%"
				padding="20px 20px 20px 20px"
			/>
			<Box
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="3rem 0px 2rem 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
					Неперевершений комфорт
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
					Пориньте в шикарну розкіш наших меблів, виготовлених на замовлення, і насолоджуйтесь відчуттям сну, як ніхто інший.
				</Text>
			</Box>
			<Box
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="3rem 0px 2rem 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
					Гастрономічні насолоди
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
					У наших ресторанах на території готелю подають страви місцевої та міжнародної кухні, приготовані відомими шеф-кухарями.
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="3rem 0px 2rem 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
					Зразкове обслуговування
				</Text>
				<Text font="--base" color="--light" margin="40px 0px 0px 0px">
					Увага нашого персоналу до деталей гарантує, що ваше перебування буде безперебійним та індивідуальним.
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="3rem 0px 2rem 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
					Стратегічне розташування
				</Text>
				<Text font="--base" color="--light" margin="40px 0px 0px 0px">
					Ідеальне розташування як для відпочинку, так і для бізнесу, з легкістю досліджуйте або з’єднуйтеся з ключовими бізнес-центрами.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0"
			sm-padding="40px 0"
			min-height="300px"
			sm-min-height="auto"
			background="--color-light"
			display="flex"
			flex-direction="column"
		>
			<Text
				as="h1"
				margin="8px 0px 32px"
				font="--lead"
				md-font="--headline2"
				color="--dark"
				max-width="850px"
				letter-spacing="1.3px"
			>
				Щоб зробити бронювання або дізнатися більше про наші ексклюзивні пропозиції, натисніть нижче:
			</Text>
			<Box min-width="100px" min-height="auto">
				<Button margin="0px 3rem 0px 0px" type="link" href="/services" text-decoration-line="initial">
					ПОСЛУГИ
				</Button>
				<Button type="link" href="/contacts" text-decoration-line="initial">
					КОНТАКТИ
				</Button>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				width="100%"
				lg-width="100%"
				display="flex"
				flex-direction="row"
				justify-content="center"
				lg-margin="0px 0px 50px 0px"
				margin="3rem 0px 0px 0px"
				max-height="400px"
			>
				<Image
					src="https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/1-3.jpg?v=2024-05-24T13:59:02.929Z"
					display="block"
					object-fit="contain"
					lg-width="100%"
					lg-height="700px"
					sm-height="300px"
					margin="0px 5px 0px 0px"
					md-height="500px"
					srcSet="https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/1-3.jpg?v=2024-05-24T13%3A59%3A02.929Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/1-3.jpg?v=2024-05-24T13%3A59%3A02.929Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/1-3.jpg?v=2024-05-24T13%3A59%3A02.929Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/1-3.jpg?v=2024-05-24T13%3A59%3A02.929Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/1-3.jpg?v=2024-05-24T13%3A59%3A02.929Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/1-3.jpg?v=2024-05-24T13%3A59%3A02.929Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/1-3.jpg?v=2024-05-24T13%3A59%3A02.929Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/2-4.jpg?v=2024-05-24T13:59:02.927Z"
					display="block"
					object-fit="contain"
					lg-width="100%"
					lg-height="700px"
					sm-height="300px"
					margin="0px 0px 0px 5px"
					md-height="500px"
					srcSet="https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-4.jpg?v=2024-05-24T13%3A59%3A02.927Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-4.jpg?v=2024-05-24T13%3A59%3A02.927Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-4.jpg?v=2024-05-24T13%3A59%3A02.927Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-4.jpg?v=2024-05-24T13%3A59%3A02.927Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-4.jpg?v=2024-05-24T13%3A59%3A02.927Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-4.jpg?v=2024-05-24T13%3A59%3A02.927Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-4.jpg?v=2024-05-24T13%3A59%3A02.927Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text2" />
			<Override slot="text1" />
			<Override slot="link3" />
			<Override slot="text3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});